import React from "react";
import ProductTemplate from "./product";
// import { FiArrowRightCircle } from "react-icons/fi";
//import Image from "@components/common/CloudinaryImage";
import Title from "@components/common/Title";
import Text from "@components/common/Text";
import { Link } from "gatsby";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    return (
        <ProductTemplate item={item}>
            {/** Qué es¿? */}
            <div className="container lg:px-0 py-20">
                <Title>
                    Les 500 preguntes imprescindibles per accedir al cos de Mossos d'Esquadra vol. 2
                    (4a edició - 23/24)
                </Title>

                <Text>
                    Després de l'èxit del primer llibre, tornem amb una edició nova del llibre
                    actualitzat per la convocatòria del 2024.
                    <br />
                    <br />
                    Més del 50% de les preguntes dels exàmens dels últims anys estaven als nostres
                    llibres.
                    <br />
                    <br />
                    Aquest segon volum és el complement ideal al primer i a la nostra guia oficial.
                    <br />
                    <br />
                    500 preguntes extretes del temari, i amb l'estructura dels llibres de preguntes
                    del método A, una pregunta, una resposta, i ampliació d'informació en algunes
                    respostes rellevants.
                    <br />
                    <br />
                    Estem convençuts que aquest llibre us aproparà una mica més a fer el vostre
                    somni una realitat.
                    <br />
                    <br />
                    A més recorda que amb el llibre et donarem accés a un mòdul exclusiu i totalment
                    gratuït ubicat dins de l'app iMossos.
                    <br />
                    <br />
                    Prepara't/da?
                    <div className="mt-5 font-semibold">
                        Compra el llibre ara i rep-lo a partir del dia 10 d'octubre.
                    </div>
                </Text>

                {/** Preview del libro */}
                <div className="container lg:px-0 py-20">
                    <Title>Preview del llibre</Title>
                    <Text className="text-justify">
                        A continuació us adjuntem un resum en format pdf del contingut del curs.
                    </Text>

                    <div className="rounded p-5 border shadow-md mt-5">
                        <embed
                            src={require("@static/documents/products/179/document2.pdf")}
                            type="application/pdf"
                            className=""
                            width="100%"
                            height="750px"
                        ></embed>
                    </div>
                </div>
            </div>

            <div className="bg-gray-100 bg-opacity-50">
                <div className="container lg:px-0 py-20 ">
                    <Title>¿Cómo puedo adquirirlo?</Title>
                    <Text className="text-justify">
                        Para adquirir el libro lo puedes hacer desde nuestra sección{" "}
                        <Link to="/tienda" className="default-link">
                            Tienda
                        </Link>{" "}
                        haciendo clic en el <strong>botón de añadir a la cesta</strong>. Recuerda
                        que puedes combinar diferentes productos en una misma compra.
                        <br />
                        <br />
                        Una vez recibamos la orden de compra procederemos al envío del libro. El
                        envío se realiza a través de la empresa GLS en la modalidad de envío
                        certificado. La empresa GLS os enviará un mail para que podáis saber en todo
                        momento donde se encuentra vuestro libro y si es necesario os llamarán por
                        teléfono para contactar con vosotros. El tiempo en ser entregado es
                        aproximadamente 48 horas.
                        <div className="text-left text-xs font-montserrat italic mt-10">
                            * Nos comprometemos en realizar el envío el día siguiente de realizar el
                            pago aunque no podemos hacernos responsables de los posibles retrasos
                            que la empresa transportista pueda ocasionar.
                        </div>
                    </Text>
                </div>
            </div>
        </ProductTemplate>
    );
};
export default Product;
